import React from "react"
import { graphql, StaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { Row, Col, Container } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"

const HeaderSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "home-feature-burgers.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage
          Tag="section"
          className="page-header"
          fluid={imageData}
          backgroundColor="#f2f3f3"
        >
          <Row>
            <Col xs={{ span: 6, offset: 3 }} md={{ span: 4, offset: 4 }} className="feature-bg">
              <h2>
                About us
              </h2>
            </Col>
          </Row>
        </BackgroundImage>
      )
    }}
  />
)

const AboutPage = () => (
  <Layout pageInfo={{ pageName: "about" }}>

    <SEO title="About" keywords={[
      `flame and hog`,
      `hog roast`,
      `lamb roast`,
      `bbq catering`
    ]} />

    <div className="grey-bg">

      <HeaderSection/>

      <Container>
        <Row className="global-padding">
          <Col md={{ span: 8, offset: 2 }}>

            <p>
              With over forty years of experience in butchery and hospitality, serving tens of thousands of satisfied clients, you can be assured of quality and exceptional service.
            </p>
            <p>
              At Flame &amp; Hog, proprietors Tim & Rachel pride themselves on giving their very best to every aspect of your function.
            </p>
            <p>
              Tim’s knowledge of meat is supported by his membership of the Institute of Meat. Having spent many years in the meat industry and working alongside many great chefs, Tim has gained a great knowledge of what is required for clients when it comes to quality of ingredients, from the meat to all the trimmings. Having supplied meat to functions for Her Majesty the Queen on several occasions you can be assured of the same quality and attention to detail for your own function.
            </p>
            <p>
              Rachel’s focus is on the hospitality side of the business, as she has over 20 years of management experience in top class restaurants and independent establishments. Her priority is giving our clients exceptional service and the confidence to know your party will run smoothly.
            </p>
            <p>
              With Tim & Rachel you are getting the best of both worlds: Tim the Cook and Rachel the host. Together they are a formidable team!
            </p>
          </Col>
        </Row>
      </Container>
    </div>

  </Layout>
)

export default AboutPage
